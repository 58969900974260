exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-guide-tsx": () => import("./../../../src/templates/guide.tsx" /* webpackChunkName: "component---src-templates-guide-tsx" */),
  "component---src-templates-news-listing-tsx": () => import("./../../../src/templates/news-listing.tsx" /* webpackChunkName: "component---src-templates-news-listing-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-publication-tsx": () => import("./../../../src/templates/publication.tsx" /* webpackChunkName: "component---src-templates-publication-tsx" */)
}

